.main {
    background-size: cover;
    height: 512px;
    width: 100%;
    background-position: center;

    @media (max-width: 576px) {
        height: 300px !important;
        background-position: top !important;
    }

    @media (max-width: 992px) {
        background-position: 70% 0;
    }

    @media (min-width: 576px) and (max-width: 768px) {
        height: 500px;
    }

    @media (min-width: 1610px) {
        background-position: initial;
    }

    &.sm {
        height: 256px;
    }

    &.md {
        height: 290px;
    }

    &.lg {
        height: 512px;
    }

    &.banner2-position {
        background-position: 77% 90%;
    }

    .main-container {
        &.order-pickup {
            padding: 0 4%;
        }
    }

    .heading {
        color: $black;
        font-family: DIN Pro;
        font-size: $extraLargeFontSize;
        font-style: normal;
        font-weight: 900;
        line-height: 120%;
        letter-spacing: -1.44px;
        text-transform: uppercase;
        padding-top: 160px;
        padding-left: 30px;
        margin-top: 0px;

        &.p-md {
            padding-top: 120px;
        }

        &.p-sm {
            padding-top: 35px;
        }

        @media (max-width: 768px) {
            font-size: $basicMediumFontSize !important;
            letter-spacing: -0.84px !important;
        }
    }

    .sub-heading {
        color: $black;
        font-family: DIN Pro;
        font-size: $mediumFontSize;
        font-style: normal;
        font-weight: $fontWeightMedium;
        line-height: 120%;
        /* 28.8px */
        padding-left: 30px;
        margin-bottom: 24px;

        @media (max-width: 768px) {
            font-size: $normalFontSize;
        }
    }
}

.order-banner {

    @media (max-width: 1024px) {
        background-image: none !important;
        height: 65px !important;

        .main-container {
            .heading {
                display: none !important;
            }

            .sub-heading {
                display: none !important;
            }
        }
    }
}

.back-icon {
    font-weight: 500;
    font-size: 16px;
}

.swiss-post-banner {
    &.sm {
        @media (max-width: 567px) {
            height: 160px !important;
        }

        @media (max-width: 768px) {
            height: 200px !important;
        }
    }
}
