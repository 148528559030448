// Main Colors
$white: #FFFFFF;
$black: #000000;
$red: #ED1C24;
$grey: #B3B3B3;
$lightGrey: #F7F7F9;
$darkGrey: #666666;
$bgGrey: #E5E5E5;
$bgWhite: #F5F5F5;
$inputGrey: #C8C8C8;
$bgGrey: #EDEDED;


// ===============
// Fonts
$primaryFont: 'DIN Pro';
$secondaryFont: 'D-DIN-PRO';
$fontWeightRegular: 400;
$fontWeightBold: 700;
$fontWeightMedium: 500;
$fontWeightExtraBold: 900;
$smallestFont: 11px;
$smallBasicFontSize: 14px;
$basicFontSize: 16px;
$normalFontSize: 20px;
$mediumFontSize: 24px;
$basicMediumFontSize: 28px;
$largeFontSize: 40px;
$extraLargeFontSize: 48px;
//  ==========
// Shadows
$boxShadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.20);
// ===========
// Border Radius
$radiusNone: 0;
$radiusSmall: 2px;
// ===========
// Letter Spacing
$extraSmallLetterSpacing: -1.44px;
$smallLetterSpacing: -0.84px;
$mediumLetterSpacing: -0.72px;
$largeLetterSpacing: -0.48px;
// ==============
// Positive Margins
$m_0: 0;
$m_auto: auto;
$m_4: 4px;
$m_5: 5px;
$m_8: 8px;
$m_24: 24px;
$m_40: 40px;
$m_172: 172px;