.main-payment {
    background-image: url(../../../../assets/images/banner2.jpg);
    background-size: cover;
    height: 256px;
    width: 100%;
    background-position: 77% 90%;

    @media (max-width: 576px) {
        background: none;
        height: 150px;
    }

    @media (min-width: 577px) and (max-width: 1024px) {
        background: none;
        height: 150px;
    }

    .heading-order {
        color: $black;
        font-size: $extraLargeFontSize;
        font-style: normal;
        font-weight: 900;
        line-height: 120%;
        letter-spacing: -1.44px;
        text-transform: uppercase;
        padding-top: 55px;
        padding-left: 30px;
        margin-top: 0px;
        margin-bottom: 0px;

        @media (max-width: 1024px) {
            padding-top: 24px;
            font-size: 28px;
            font-weight: 700;
        }
    }

    .order-subHeading {
        color: #000;
        font-family: DIN Pro;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 28.8px */
        padding-left: 30px;

        @media (max-width: 768px) {
            font-size: 20px;
            padding-top: 24px;

        }
    }
}

.main-container-details {
    max-width: 1044px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    padding: 0;

    @media (min-width: 1600px) {
        max-width: 1260px;
    }

    .back-icon-area {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-top: 24px;
        padding-left: 24px;
        cursor: pointer;

        span {
            color: $black;
            text-align: center;
            font-size: $basicFontSize;
            font-style: normal;
            font-weight: $fontWeightMedium;
            line-height: 100%;
            text-transform: uppercase;
        }
    }

    .damage-area {
        padding-top: 35px;
        padding-bottom: 15px;

        .damage-btn {
            color: $black;
            text-align: center;
            font-size: $basicFontSize;
            font-style: normal;
            font-weight: $fontWeightMedium;
            line-height: 100%;
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 0;
            cursor: pointer;
            outline: none;
            background: none;
            border: none;
        }
    }

    .cards-section-payment {
        background: $white;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.20);
        backdrop-filter: blur(15px);
        padding: 24px;
        margin-top: -55px;
        flex-direction: row;
        gap: 70px;
        margin-bottom: 158px;

        @media (max-width: 1024px) {
            gap: 24px !important;
            margin-top: 0px !important;
            box-shadow: none;
            flex-direction: column;
        }

        .section-left {

            .applePay {
                width: 49%;
                background-color: $black;
                padding: 12px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
            }

            .googlePay {
                width: 49%;
                background-color: $black;
                padding: 12px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
            }
        }
    }
}

.p-Field {
    .p-FieldLabel {
        display: none !important;
    }
}
