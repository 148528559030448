@import './variables.scss';
@import '../components/Footer/Footer.scss';
@import '../components/Button/Button.scss';
@import '../components/SkiCondition/SkiCondition.scss';
@import '../components/SkiService/SkiService.scss';
@import '../containers/Onboarding/components/BannerItem/BannerItems.scss';
@import '../containers/OnboardingStep2/components/BannerStep2/BannerStep2.scss';
@import '../containers/Pricing/components/PricingCard/PricingCard.scss';
@import '../containers/Pricing/components/Subscriptions/subscriptions.scss';
@import '../containers/OnboardingStep2/components/OtherRelevantDamagesModal/OtherRelevantDamagesModal.scss';
@import '../containers/Order/components/BannerOrder/style.scss';
@import '../containers/Order/components/NoBagCard/style.scss';
@import '../containers/Order/components/SkiBagCard/style.scss';
@import '../containers/Order/components/ServiceSummary/style.scss';
@import '../containers/Order/components/SummaryCard/style.scss';
@import '../components/Input/style.scss';
@import '../containers/UserDetails/components/UserDetailsForm/style.scss';
@import '../containers/UserDetails/components/MobileSummary/style.scss';
@import '../containers/Payment/componnets/PaymentBanner/PaymentBanner.scss';
@import '../containers/Payment/componnets/PaymentSuccess/style.scss';
@import '../containers/Payment/componnets/CheckoutForm/style.scss';
@import '../containers/SwissPost/components/SwissPostBannner/SwissPostBannner.scss';
@import '../containers/OrderDetails/style.scss';
@import '../components/Banner/style.scss';
@import '../components/OnboardingCard/style.scss';
@import '../containers/OrderDetails/style.scss';
@import '../containers/OrderDetails/components/ServiceCard/style.scss';
@import '../containers/OrderDetails/components/SkiBagCard/style.scss';

body {
    margin: 0;
    font-family: 'DIN Pro' !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
