.pricing-card {
    display: flex;
    height: 100%;
    padding-bottom: 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.10);
    cursor: pointer;

    &:hover {
        background: $bgGrey;
    }

    .pricing-header {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        background: $black;
        position: relative;
        border: 1px solid;
        border-bottom: 0px;
        min-height: 60px;
        padding-left: 5px;
        padding-right: 5px;

        .title {
            color: $white;
            text-align: center;
            font-family: D-DIN-PRO;
            font-size: $normalFontSize;;
            font-style: normal;
            font-weight: $fontWeightBold;
            line-height: 100%;
            text-transform: uppercase;
        }

        .best-option {
            display: flex;
            transform: rotate(-20deg);
            padding: 4px 8px;
            align-items: flex-start;
            gap: 8px;
            position: absolute;
            left: -15px;
            top: 0;
            border-radius: 2px;
            background: $red;
            color: $white;
            font-family: DIN Pro;
            font-size: $smallBasicFontSize;
            font-style: normal;
            font-weight: $fontWeightBold;
            line-height: normal;
            text-transform: uppercase;
        }
    }

    .description {
        color: $black;
        font-family: DIN Pro;
        font-size: $basicFontSize;
        font-style: normal;
        font-weight: $fontWeightMedium;
        line-height: 150%;
        letter-spacing: -0.48px;
        padding: 24px;
        min-height: 128px;
        margin-bottom: 0;
    }

    .bullets-section {
        padding: 8px 24px;
        min-height: 267px;

        .bullets {
            display: flex;
            align-items: baseline;
            gap: 8px;
            margin-bottom: 24px;
            padding-left: 13px;

            span {
                color: $black;
                font-family: DIN Pro;
                font-size: $basicFontSize;
                font-style: normal;
                font-weight: $fontWeightRegular;
                line-height: 120%;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    margin-bottom: 5px;
                    position: relative;
                    padding-left: 20px;
                    color: #000;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;

                    &:before {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        left: -0.5em;
                        top: 0;
                        transform: rotate(45deg);
                        height: 1em;
                        width: 0.5em;
                        border-bottom: 0.15em solid #ED1C24;
                        border-right: 0.15em solid #ED1C24;
                    }
                }
            }
        }
    }
}

.pricing-card-body {
    .wrapper {
        height: calc(100% - 60px);
        border: 1px solid $black;
        border-top: none;

        .border-section {
            border: 3px solid transparent;
            border-top: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 100%;
        }
    }
}

.pricing-card-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .price {
        color: $black;
        font-family: DIN Pro;
        font-size: $largeFontSize;
        font-style: normal;
        font-weight: $fontWeightBold;
        line-height: 120%;
        text-transform: uppercase;
        text-align: center;
    }

    .price-description {
        color: $black;
        font-family: DIN Pro;
        font-size: $smallestFont;
        font-style: normal;
        font-weight: $fontWeightMedium;
        line-height: normal;
        padding: 0 16px;
    }
}

.selected {
    background: $bgGrey;

    .wrapper {
        border: 4px solid $black;
        border-top: none;

        .border-section {
            border: 0px solid transparent;
            border-top: none;
        }
    }
}

.pricing-card-mobile {
    @media(max-width: 1024px) {
        padding-top: 28px;
        padding-left: 15px;
    }
}
