.price-main {
    background-size: cover;
    height: 512px;
    width: 100%;
    background-position: 77% 7%;

    @media (max-width: 768px) {
        height: 300px !important;
    }

    .heading {
        color: $black;
        font-family: DIN Pro;
        font-size: $extraLargeFontSize;
        font-style: normal;
        font-weight: 900;
        line-height: 120%;
        letter-spacing: -1.44px;
        text-transform: uppercase;
        padding-top: 100px !important;
        padding-left: 30px;
        margin-top: 0px;
        padding-bottom: 20px;

        @media (max-width: 768px) {
            font-size: $basicMediumFontSize !important;
            letter-spacing: -0.84px !important;
        }
    }

    .sub-heading {
        color: $black;
        font-family: DIN Pro;
        font-size: $mediumFontSize;
        font-style: normal;
        font-weight: $fontWeightMedium;
        line-height: 120%;
        /* 28.8px */
        padding-left: 30px;
        margin-bottom: 24px;

        @media (max-width: 768px) {
            font-size: $normalFontSize;
        }
    }
}

.pricing-container {
    @media(max-width: 1024px) {
        margin-top: 14px;
    }
}

.main-container {
    max-width: 1044px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 1600px) {
        max-width: 1260px;
    }

    .price-cards-section {
        background: $white;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.20);
        backdrop-filter: blur(15px);
        padding: 24px;
        margin-top: -168px;

        @media (max-width: 576px) {
            margin-top: 0;
            box-shadow: none;
            padding: 0;
        }

        .cards-area-pricing {
            display: flex;
            flex-direction: row;
            height: 100%;
            align-items: stretch;
            flex-wrap: wrap;
            scrollbar-width: none;

            @media (max-width: 1024px) {
                overflow-x: scroll;
                flex-wrap: nowrap;

                &::-webkit-scrollbar {
                    display: none !important;
                }
            }
        }

        .showmore-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 13px;

            img {
                cursor: pointer;
            }

            span {
                color: $black;
                text-align: center;
                font-family: DIN Pro;
                font-size: $basicFontSize;
                font-style: normal;
                font-weight: $fontWeightMedium;
                line-height: 100%;
                text-transform: uppercase;
                cursor: pointer;
            }
        }
    }
}

.pricing-heading {
    @media (max-width: 1024px) {
        padding-top: 85px !important;
    }
}

@media (max-width: 576px) {
    .pricing-container-mobile {
        .back-icon-area {
            padding-top: 85px !important;
        }

        .p-md {
            padding-top: 24px !important;
        }
    }
}
