.success-wrapper {
    border: 1px solid $black;
    padding: 0px 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 500px;
    margin-top: 20px;

    h3 {
        color: $black;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
        margin-top: 28px;
    }

    p {
        color: $black;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: 0.32px;
        margin-bottom: 0px;
        margin-top: 16px;
    }
}

.success-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .success-btn {
        margin-bottom: 0px;
        margin-top: 32px;
    }
}

.mobile-wrapper {
    img {
        width: 26px;
    }

    h3 {
        color: $black;
        font-family: DIN Pro;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        /* 28px */
        letter-spacing: -0.84px;
        text-transform: uppercase;
    }
}