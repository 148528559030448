.user-summary-wrapper {
    margin-top: 40px;

    .mobile-summery {
        border: 4px solid $black;

        .summary {
            padding: 16px 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                color: $black;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                /* 19.2px */
                letter-spacing: -0.48px;
            }
        }

        .detail-price {
            padding: 16px 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: $black;
            color: $white;

            p {
                color: $white;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                margin-bottom: 0px;
                /* 19.2px */
            }

            span {
                color: $white;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                /* 20px */
                text-transform: uppercase;
            }
        }

    }
}
