.input-container {
    border: 1px solid $inputGrey;
    padding: 16px;
    width: 100% !important;
    margin-top: 12px;

    .main-input {
        border: none;
        outline: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        width: 100%;
    }

    &.input-focused {
        border: 1px solid $black;
    }

    &.error-input {
        border: 1px solid $red;

        .main-input {
            color: $red
        }
    }
}

.error-message {
    color: $red;
    font-family: DIN Pro;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 13.2px */
    opacity: 0;
    margin-top: 4px;
    margin-bottom: 3px;
    min-height: 12px;

    &.error-show {
        opacity: 1;
    }

}