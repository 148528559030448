.summary-main {

    @media (max-width: 1024px) {
        display: none !important;
    }

    .summary-title {
        color: #000;
        font-family: DIN Pro;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 24px */
        text-transform: uppercase;
        margin-bottom: 24px;
    }

    .summary-container {
        border: 2px solid black;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 300px;

        .summary-cards-wrapper {
            max-height: 432px;
            overflow-y: scroll;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .add-another {
            color: #000;
            font-family: DIN Pro;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-transform: uppercase;
        }

        .card-bottom {
            @media (max-width: 991px) {
                margin-top: 30px;
            }

            .price-detail {
                padding: 24px;
                background: rgba(0, 0, 0, 0.05);

                .individual-price {
                    padding: 8px 0px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .detail-price-title {
                        color: #000;
                        font-family: DIN Pro;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        gap: 10px
                    }

                    .detail-price-amount {
                        color: #000;
                        text-align: center;
                        font-family: DIN Pro;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%;
                        /* 24px */
                        text-transform: uppercase;
                    }
                }

                .description-line {
                    color: #000;
                    font-family: DIN Pro;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 13.2px */
                    opacity: 0.5;
                }

            }

            .total-price {
                background-color: $black;
                padding: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .total-price-title {
                    color: #FFF;
                    font-family: DIN Pro;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 19.2px */
                }

                .total-price-amount {
                    color: #FFF;
                    text-align: center;
                    font-family: DIN Pro;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    /* 24px */
                    text-transform: uppercase;
                }
            }
        }

        .add-new-text {
            text-align: right;

            .add-new-icon {
                text-align: end;
                height: 20px;
                width: 20px;
                margin-top: -7px;
                margin-right: 5px;
            }
        }
    }

    &.mobile-summary {
        @media (max-width: 1024px) {
            display: block !important;
            width: 100%;
        }
    }
}

.transfer-fee-toolti {
    width: 260px;
    word-break: break-all;
    font-size: 12px;
}
