.text-section {
    padding-top: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1024px) {
        padding: 20px 24px 72px 24px;
    }

    > p {
        text-align: center;
    }
}
