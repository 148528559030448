.main-container-order {
    max-width: 1044px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative;

    @media (min-width: 1600px) {
        max-width: 1260px;
    }

    .back-icon-area {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-top: 24px;
        padding-left: 24px;
        cursor: pointer;

        span {
            color: $black;
            text-align: center;
            font-size: $basicFontSize;
            font-style: normal;
            font-weight: $fontWeightMedium;
            line-height: 100%;
            text-transform: uppercase;
        }
    }

    .damage-area {
        padding-top: 35px;
        padding-bottom: 15px;

        .damage-btn {
            color: $black;
            text-align: center;
            font-size: $basicFontSize;
            font-style: normal;
            font-weight: $fontWeightMedium;
            line-height: 100%;
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 0;
            cursor: pointer;
            outline: none;
            background: none;
            border: none;
        }
    }

    .cards-section-order {
        background: $white;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.20);
        backdrop-filter: blur(15px);
        padding: 24px;
        margin-top: -55px;
        gap: 70px;
        margin-bottom: 158px;

        @media (max-width: 1024px) {
            margin-top: 0px !important;
            box-shadow: none;
            margin-bottom: 80px;
        }

        .section-left {

            .order-heading {
                color: $black;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                text-transform: uppercase;
            }

            .order-description {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: -0.48px;
                margin-top: 16px;

                span {
                    color: #000;
                    font-family: DIN Pro;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                    letter-spacing: -0.48px;
                }
            }

            .Bags-items-wrapper {
                height: 530px;
                overflow-y: scroll;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .continue-button {
                margin: 24px 0px;
                width: 212px;
            }
        }


    }

}

.Modal-section {
    position: fixed;
    height: 100px;
    width: 100%;
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    @media (max-width: 1024px) {
        display: flex !important
    }

    .order-btn-wrapper {
        background: $white;
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 387px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.20);
        backdrop-filter: blur(15px);

        .order-modal-btn {
            max-width: 330px !important;
            margin: 0px !important;
            white-space: break-spaces;
            min-width: 280px;
        }
    }

}
