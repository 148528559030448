.card-number {
    border: 1px solid $grey;
    padding: 15px 16px;
    font-family: DIN Pro;
}

.expiry-date {
    border: 1px solid $grey;
    padding: 15px 16px;
    font-family: DIN Pro;
}

.cvc-input {
    border: 1px solid $grey;
    padding: 15px 16px;
    font-family: DIN Pro;
}