.orderStatus-banner {
    background: url('../../assets/images/orderstatusbg.png');
    height: 263px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 75% 63%;

    div {
        height: 100%;
        padding: 38px 24px;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%);

        h2 {
            color: $white;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            /* 32px */
            text-transform: uppercase;
            max-width: 320px;
        }

        p {
            color: $white;
            font-family: DIN Pro;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            max-width: 242px;

        }
    }
}

.orderStatus-steps {
    background: $black;
    padding: 34px 0px 45px 15px !important;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    align-items: flex-start !important;
    scrollbar-width: none;

    @media (max-width: 1024px) {
        padding-left: 60px !important;
        padding-top: 25px !important;
        padding-bottom: 34px !important;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}


.orderStatus-main {
    margin-top: 40px;

    @media (max-width: 1024px) {
        padding: 0px !important;
    }


    .order-status-left {
        @media (max-width: 1024px) {
            padding: 0px 24px !important;
        }

        .message {
            p {
                color: $black;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 7px;

                @media (max-width: 1024px) {
                    font-size: 16px;
                }
            }

            span {
                color: $black;
                font-family: DIN Pro;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media (max-width: 1024px) {
                    font-size: 16px;
                }
            }
        }

        .checkout-userdetails {
            margin-top: 32px;

            h3 {
                color: $black;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;

                @media (max-width: 1024px) {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 120%;
                }
            }

            div {
                margin-top: 16px;

                span {
                    color: #666;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;


                    @media (max-width: 1024px) {
                        font-size: 16px;
                    }

                }

                p {
                    color: $black;
                    font-family: DIN Pro;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin: 0;

                    @media (max-width: 1024px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .order-status-right {
        border: 1px solid $black;
        height: fit-content;

        @media (max-width: 1024px) {
            border: none;
        }


        .order-status-title {
            color: $black;
            font-family: DIN Pro;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            margin: 16px 21px;
        }
    }
}

.feedback-section {
    margin-top: 80px;
    padding: 80px 24px 48px 24px;
    background: $bgGrey;

    @media (max-width: 992px) {
        margin-top: 0;
        margin-bottom: 120px;
        border-top: 1px solid $black;
        padding-top: 24px;
    }

    @media (min-width: 992px) {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100% !important;

    }

    h3 {
        font-weight: $fontWeightBold;
        font-size: 24px;
        line-height: 30.05px;
    }

    p {
        font-weight: $fontWeightRegular;
        font-size: 12px;
        line-height: 14px;

    }

    .feedback-btn {
        padding-left: 47px;
        padding-right: 47px;
    }
}
