.orderStatus-skiBag {
    .details-wrapper {
        padding: 24px;

        @media (max-width: 1024px) {
            padding: 24px 24px 34px 24px;
        }

        h3 {
            color: $black;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media (max-width: 1024px) {
                font-size: 20px;
                font-weight: 500;
                line-height: 120%;
            }

        }

        .deliver-date-text {
            p {
                color: $black;
                font-family: DIN Pro;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 6px;

                @media (max-width: 1024px) {
                    font-size: 16px;
                }
            }

            span {
                color: #767676;
                font-family: DIN Pro;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media (max-width: 1024px) {
                    font-size: 16px;
                }
            }
        }

        div {
            @media (max-width: 1024px) {
                margin-bottom: 32px;
            }

            .location-text {
                color: $black;
                font-family: DIN Pro;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 0px;

                @media (max-width: 1024px) {
                    font-size: 16px;

                }
            }
        }

    }

    .skiBag-image {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%);

        @media (max-width: 1024px) {
            padding: 24px 24px 0px 24px;
            margin-top: 34px;
        }

        div {
            height: 140px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;

            @media (max-width: 1024px) {
                background-size: contain;
                background-position: center;
            }
        }
    }
}
