.swiss-post-main-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    height: 1700px;
    width: 1044px !important;
    padding: 24px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    backdrop-filter: blur(15px);
    align-items: flex-start;
    gap: 24px;
    margin-top: -24px;
    margin-bottom: 200px;

    @media (min-width: 1600px) {
        max-width: 1260px;
    }

    @media (max-width: 768px) {
        width: 100% !important;
    }

    @media (max-width: 992px) {
        min-height: 2100px;
        box-shadow: none;
        margin-bottom: 0;
    }

    .info-container {
        border: 2px solid $red;
        display: flex;
        width: 100%;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        .info-heading {
            font-weight: $fontWeightBold;
            color: $red;
            font-size: 16px;
        }

        .info-description {
            color: $red;
            font-size: 16px;

        }
    }
}

.swiss-post-banner {
    height: 280px !important;

    @media (min-width: 576px) and (max-width: 768px) {
        height: 247px !important;
    }

    @media (max-width: 1024px) {
        height: 240px !important;
    }

    @media (max-width: 1024px) {
        background: none !important;
    }

    .main-container {
        .back-icon-area {
            @media (max-width: 1024px) {
                display: none !important;
            }
        }
    }
}
