.modal-container {
    .modal-content {
        border-radius: 0;
    }

    .modal-body {
        padding: 0;
    }

    .header {
        display: flex;
        padding: 24px 40px;
        justify-content: space-between;

        @media (max-width: 768px) {
            display: none !important;
        }

        h2 {
            color: $black;
            text-align: center;
            font-size: $basicFontSize;
            font-style: normal;
            font-weight: $fontWeightMedium;
            line-height: 100%;
            text-transform: uppercase;
        }

        img {
            cursor: pointer;

            @media (max-width: 768px) {
                display: none !important;
            }
        }
    }

    .descriptions {
        padding-left: 40px;
        padding-right: 33px;
        padding-top: 30px;

        @media (min-width: 768px) {
            padding-bottom: 15px;
        }

        h1 {
            color: $black;
            font-size: $mediumFontSize;;
            font-style: normal;
            font-weight: $fontWeightBold;
            line-height: 100%;
            text-transform: uppercase;

            @media (max-width: 768px) {
                display: none !important;
            }
        }

        p {
            color: $black;
            font-size: $basicFontSize;
            font-style: normal;
            font-weight: $fontWeightRegular;
            line-height: 150%;
            letter-spacing: -0.48px;
        }

        .stockli-text {
            color: $black;
            font-size: $basicFontSize;
            font-style: normal;
            font-weight: $fontWeightMedium;
            line-height: 150%;
            letter-spacing: -0.48px;
        }

        .mobile-header {
            display: none;

            @media (max-width: 768px) {
                display: block;
                color: $black;
                font-size: $normalFontSize;;
                font-style: normal;
                font-weight: $fontWeightMedium;
                line-height: 120%;
                text-transform: uppercase;
            }
        }

        .back-icon-mobile {
            display: none;

            @media (max-width: 768px) {
                display: flex;
                align-items: center;
                gap: 8px;
                padding-bottom: 24px;

                span {
                    color: $black;
                    font-size: $basicFontSize;
                    font-weight: $fontWeightMedium;
                    line-height: 100%;
                    text-transform: uppercase;
                }
            }
        }
    }

    .back-container {
        padding: 30px 33px 24px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 48px;
        }

        span {
            color: $black;
            font-size: $basicFontSize;
            font-style: normal;
            font-weight: $fontWeightMedium;
            line-height: 100%;
            text-transform: uppercase;
            padding-left: 8px;
        }


        .store-btn {
            background-color: $white;
            border: 1px solid $black;
            display: flex;
            padding: 16px 32px;
            align-items: center;
            justify-content: center;
            color: $black;
            border-radius: 0;
            text-align: center;
            font-size: $basicFontSize;
            font-style: normal;
            font-weight: $fontWeightMedium;
            line-height: 100%;
            text-transform: uppercase;
            background: linear-gradient(to right, black 50%, $white 50%);
            background-size: 200% 100%;
            background-position: right bottom;
            transition: all .2s ease-out;
            font-weight: $fontWeightMedium;
            line-height: 100%;
            text-transform: uppercase;

            @media (max-width: 768px) {
                background: $black;
                color: $white;
                width: 100%;
            }

            &:hover {
                background-position: left bottom;
                color: $white;
            }
        }
    }
}
