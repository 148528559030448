.no-bag-container {
    border: 1px solid #000;
    padding: 24px 16px;
    display: flex;
    justify-content: space-between;

    .text-section-order {
        .tag-line {
            color: #000;
            font-family: DIN Pro;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin-bottom: 6px;
        }

        .no-bag-title {
            color: #000;
            font-family: DIN Pro;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            margin-bottom: 0px;
        }
    }

    .no-bag-image {
        display: flex;
        align-items: flex-end;
    }
}

.no-selected-bag {
    border: 4px solid black;
    background: #E5E5E5;
}