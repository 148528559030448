.skibag-card-container {
    border: 1px solid #000;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    margin: 8px 0px;
    cursor: pointer;


    .skibag-content {

        @media (max-width: 1024px) {
            flex-direction: column;
            gap: 20px;
            flex-grow: 1;
        }

        .ski-bag-image {
            width: 174px;
            height: 78px;
            object-fit: cover;

            @media (max-width: 1024px) {
                align-self: center;
            }
        }

        .bag-text-wrapper {
            padding-left: 24px;

            @media (max-width: 1024px) {
                padding-left: 0px;
            }
        }

        .bag-title {
            color: #000;
            font-family: DIN Pro;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 19.2px */
            margin-bottom: 6px;
        }

        .bag-price {
            color: #000;
            font-family: DIN Pro;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            /* 20px */
            text-transform: uppercase;
            margin-bottom: 0px;
        }
    }

}

.selected-bag {
    border: 4px solid black;
    background: #E5E5E5;
}
