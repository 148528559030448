.footer-section {
    min-height: 305px;
    background: $lightGrey;
    padding: 40px;

    @media (max-width: 1024px) {
        height: 800px;
    }

    @media (max-width: 768px) {
        display: none;
    }

    .main-container {
        .img-area {
            img {
                width: 120px;
            }

            h2 {
                color: $black;
                font-family: DIN Pro;
                font-size: $basicFontSize;
                font-style: normal;
                font-weight: $fontWeightBold;
                line-height: 200%;
                margin-top: 0;
                margin-bottom: 0;
            }

        }

    }

    .footer-main {
        display: flex;
        gap: 85px;
        padding-left: 75px;

        @media (max-width: 1024px) {
            flex-direction: column;
            gap: 10px;
        }
    }
}

.footer-section-mini {
    height: 80px;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        height: 140px;
        background-color: $bgWhite;
        padding: 24px;
    }

    .footer-main-mini {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 75px;

        @media (max-width: 1024px) {
            flex-direction: column;
            padding-left: 0px;
            text-align: center;
        }
    }

    .social-icons {
        display: flex;
        gap: 16px;
        padding-bottom: 10px;
    }
}


.mobile-footer {
    background: $lightGrey;
    padding: 67px 24px;
    display: none;

    @media (max-width: 768px) {
        display: block;
    }

    .accordion-item {
        border: none;
    }

    .accordion-button {
        background: $lightGrey;
        color: $black;
        font-size: $basicFontSize;
        font-style: normal;
        font-weight: $fontWeightBold;
        line-height: 200%;

        &:focus {
            border-color: transparent;
            box-shadow: none;
        }
    }

    .accordion-collapse {
        background: $lightGrey;
    }
}


.footer-section,
.footer-section-mini,
.mobile-footer {
    a {
        color: $darkGrey;
        font-size: $basicFontSize;
        font-style: normal;
        font-weight: $fontWeightRegular;
        line-height: 200%;
        margin-bottom: 4px;
        text-decoration: none;

        &:hover {
            color: $black;
        }
    }

    p {
        color: $darkGrey;
        font-size: $basicFontSize;
        font-style: normal;
        font-weight: $fontWeightRegular;
        line-height: 200%;
        margin-bottom: 4px;
    }
}