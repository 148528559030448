.select-btn {
    display: flex;
    border-radius: 0;
    color: $black;
    min-width: 185px;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    // width: 100%;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid $black;
    gap: 8px;
    font-size: $basicFontSize;
    margin-bottom: 172px;
    background: linear-gradient(to right, $black 50%, $white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .2s ease-out;
    font-weight: $fontWeightMedium;
    line-height: 100%;
    text-transform: uppercase;
    outline: none;

    @media (max-width: 768px) {
        margin-bottom: 40px !important;
        width: 100% !important;
        max-width: 100% !important;
    }

    &:hover {
        background-position: left bottom;
        color: $white;
        border: 2px solid $black;
    }

    &.without-margin-btn {
        margin-bottom: 0 !important;
        min-width: 275px !important
    }

    &.black-bg-btn {
        background: $black;
        color: $white;
    }

    &:disabled {
        border: 2px solid $grey !important;
        color: $grey !important;
        display: flex;
        border-radius: 0;
        color: $black;
        max-width: 274px;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-size: $basicFontSize;
        font-weight: $fontWeightMedium;
        line-height: 100%;
        text-transform: uppercase;
        pointer-events: none !important;
    }

    &:disabled.disable-secondary {
        color: $white !important;
        background-color: $grey;
        min-width: 275px !important
    }
}