.main-user-details {
    background-image: url(../../../../assets/images/banner2.jpg);
    background-size: cover;
    height: 256px;
    width: 100%;
    background-position: 77% 90%;

    @media (max-width: 576px) {
        background: none;
        height: 122px;
    }

    @media (min-width: 577px) and (max-width: 1024px) {
        background: none;
        height: 175px;
    }

    .heading-order {
        color: $black;
        font-size: $extraLargeFontSize;
        font-style: normal;
        font-weight: 900;
        line-height: 120%;
        letter-spacing: -1.44px;
        text-transform: uppercase;
        padding-top: 55px;
        padding-left: 30px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .heading-order-mobile {
        padding-top: 35px !important;
        font-size: 28px;
    }

    .order-subHeading {
        color: #000;
        font-family: DIN Pro;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 28.8px */
        padding-left: 30px;
    }
}

.main-container-details {
    max-width: 1044px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative;

    @media (min-width: 1600px) {
        max-width: 1260px;
    }

    .back-icon-area {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-top: 24px;
        padding-left: 24px;
        cursor: pointer;

        span {
            color: $black;
            text-align: center;
            font-size: $basicFontSize;
            font-style: normal;
            font-weight: $fontWeightMedium;
            line-height: 100%;
            text-transform: uppercase;
        }
    }

    .damage-area {
        padding-top: 35px;
        padding-bottom: 15px;

        .damage-btn {
            color: $black;
            text-align: center;
            font-size: $basicFontSize;
            font-style: normal;
            font-weight: $fontWeightMedium;
            line-height: 100%;
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 0;
            cursor: pointer;
            outline: none;
            background: none;
            border: none;
        }
    }

    .cards-section-order {
        background: $white;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.20);
        backdrop-filter: blur(15px);
        padding: 24px;
        margin-top: -55px;
        gap: 70px;
        margin-bottom: 158px;

        @media (max-width: 576px) {
            margin-top: 0px !important;
        }

        @media (max-width: 1024px) {
            box-shadow: none
        }

        .section-left {

            .fill-details {
                color: $black;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 24px */
                text-transform: uppercase;
                margin-bottom: 24px;
            }

            .user-btn-wrapper {
                margin-top: 70px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .user-detail-btn {
                    margin-bottom: 0px;
                    padding-top: 16px;
                }
            }
        }
    }
}

.mobile-continue {
    position: fixed;
    bottom: 0px;
    padding: 24px;
    background: $white;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(15px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    // margin-bottom: 40px;

    .order-modal-btn {
        margin: 0px !important;
    }
}

.form-check {
    .form-check-label {
        color: $darkGrey;
    }
}


.form-check-input {
    &:checked {
        background-color: #000 !important;
        border-color: #000 !important;
    }
}

.radio-check-wrapper {
    input {
        border-color: red;
    }

    label {
        color: red !important
    }
}

.form-check-input:focus {
    outline: none !important;
    box-shadow: none !important;
}

.mobile-proceed {
    min-width: 275px !important
}

.user-details-heading-mobile {
    @media (max-width: 1024px) {
        font-size: 28px !important;
        padding-top: 30px !important;

    }
}
