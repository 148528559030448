.main-container {
    max-width: 1044px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 1600px) {
        max-width: 1260px;
    }

    .back-icon-area {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-top: 24px;
        padding-left: 24px;
        cursor: pointer;

        b {
            color: $black;
            text-align: center;
            font-size: $basicFontSize;
            font-style: normal;
            font-weight: $fontWeightMedium;
            line-height: 100%;
            text-transform: uppercase;
        }
    }

    .damage-area {
        padding-top: 35px;
        padding-bottom: 15px;

        .damage-btn {
            color: $black;
            text-align: center;
            font-size: $basicFontSize;
            font-style: normal;
            font-weight: $fontWeightMedium;
            line-height: 100%;
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 0;
            cursor: pointer;
            outline: none;
            background: none;
            border: none;
        }
    }

    .cards-section {
        background: $white;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.20);
        backdrop-filter: blur(15px);
        padding: 24px;
        margin-top: -150px;

        @media (max-width: 576px) {
            margin-top: 0px !important;
            box-shadow: none
        }

        .cards-area {
            display: flex;
            gap: 8px;
            overflow-x: scroll !important;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        h3 {
            color: $black;
            font-size: $mediumFontSize;
            font-style: normal;
            font-weight: $fontWeightMedium;
            line-height: 100%;
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 8px;

            @media (max-width: 768px) {
                font-weight: $fontWeightBold;
            }
        }

        .order-step {
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;

            @media (max-width: 768px) {
                font-size: $basicFontSize;
                line-height: 24px;
                font-weight: 700;
                letter-spacing: -0.03em;
            }
        }
    }

    .text-section {
        padding-top: 72px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .text-des {
            text-align: center;

            @media(max-width: 768px) {
                text-align: start;
            }
        }
    }
}

.onboarding-step-banner {
    padding-top: 30px;
    max-width: 1044px !important;

    @media(max-width: 768px) {
        padding-top: 0px;
    }
}

.bold-desc {
    @media (max-width: 1024px) {
        display: block;
    }
}

.onboarding-bold-desc {
    @media (max-width: 1024px) {
        margin-top: 15px;
    }
}
