.summery-card {

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 32px;
    padding-bottom: 40px;
    border-top: 1px solid rgba(0, 0, 0, 0.20);

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-end;
        gap: 16px;
        padding-bottom: 10px;
        border-top: none
    }

    .summery-card-title {
        color: #000;
        font-family: DIN Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.48px;
        margin-bottom: 0px;
        text-align: left;

        @media (max-width: 768px) {
            text-align: right;
        }
    }

    .summary-card-price {
        color: #000;
        font-family: DIN Pro;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
        margin-bottom: 0px;
        text-align: right;

        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
}
