@media (max-width: 768px) {
    .onboarding-container-mobile {
        .back-icon-area {
            padding-top: 160px !important;
        }

        .p-md {
            padding-top: 10px !important;
        }
    }

}

@media (max-width: 576px) {
    .onboarding-container-mobile {
        .p-md {
            padding-top: 10px !important;
        }
    }
}
