.orderStatus-ServiceCard {
    padding: 10px 24px 30px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    @media (max-width: 1024px) {
        padding: 45px 24px;
        border: none;
    }

    h3 {

        color: $black;
        font-family: DIN Pro;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        @media (max-width: 1024px) {
            font-size: 20px;
            font-weight: 500;
            line-height: 120%;
        }
    }

    .key-points {
        margin-top: 16px;

        div {
            margin-bottom: 8px;

            @media (max-width: 1024px) {
                margin-bottom: 24px;


            }

            p {
                color: #666;
                font-family: DIN Pro;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;

                @media (max-width: 1024px) {
                    color: $black;
                    font-size: 16px;
                    padding-right: 60px;

                }
            }
        }
    }

    .transport {

        div {

            p {
                color: #000;
                font-family: DIN Pro;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 0px;

                @media (max-width: 1024px) {
                    font-size: 16px;
                }


            }

            span {
                color: #767676;
                font-family: DIN Pro;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media (max-width: 1024px) {
                    font-size: 16px;
                }

            }
        }
    }

    .location {

        span {
            color: $black;
            font-family: DIN Pro;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media (max-width: 1024px) {
                font-size: 16px;
            }
        }
    }
}
