.card-container {
    background-size: contain;
    height: 296px;
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;

    @media (max-width: 768px) {
        min-width: 280px;
    }

    &.frame {
        img {
            box-shadow: 0px 4px 4px 0px #00000040;
            border: 4px solid #000000;
        }
    }

    .card-heading {
        color: $white;
        font-size: $mediumFontSize;;
        font-style: normal;
        font-weight: $fontWeightBold;
        line-height: 100%;
        letter-spacing: -0.72px;
        text-transform: uppercase;
        margin-bottom: 5px;
        margin-top: 0;
        padding-left: 24px;

    }

    .card-description {
        color: $white;
        font-size: $basicFontSize;
        font-style: normal;
        font-weight: $fontWeightRegular;
        line-height: 100%;
        letter-spacing: -0.48px;
        margin-bottom: 0;
        margin-top: 0;
    }

    .card-heading-select {
        color: $white;
        font-size: $mediumFontSize;
        font-style: normal;
        font-weight: $fontWeightBold;
        line-height: 100%;
        letter-spacing: -0.72px;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: $black, $alpha: 0.6);
        position: absolute;
    }

    .selected-ski {
        background: rgba($color: $black, $alpha: 0.6);
    }

    .select-btn-white {
        display: flex;
        padding: 16px 0px;
        justify-content: center;
        align-items: center;
        width: 232px;
        margin-left: 24px;
        margin-bottom: 24px;
        border: 1px solid $white;
        background: transparent;
        color: $white;
        font-size: $basicFontSize;
        font-weight: $fontWeightBold;
        line-height: 100%;
        letter-spacing: -0.48px;
        text-transform: uppercase;
        display: none;

        @media (max-width: 768px) {
            display: flex;
        }
    }

    .desc-wrapper {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 1) 100%);
        position: absolute;
    }
}
